& {
	background-image: url("/assets/portal/hero.jpg");
	background-size: cover;
	background-position: top;
	background-repeat: no-repeat;
	background-attachment: fixed;
	height: 95vh;
}

.hero {
	color: white;
	text-align: center;
	min-height: 100%;
	height: auto;
	h1, h2 {
		color: white;
	}

	a {
		color: white;
		text-decoration: underline;
	}

}

.logo {
	padding: 50px;
}

.message {
	margin-bottom: 30px;
	text-align: center;

	h2, p, a {
		color: #FFFFFF;
	}

	a {
		text-decoration: underline;

		&:hover {
			text-decoration: none;
		}
	}
}

form {
	padding: 10px 0 10px 0;

}
