@import "global-styles/variables.less";

.header {
	nav.main.navbar {
		height: 100px;
		margin: 0;

		border: none;
		border-radius: 0;

		background-color: @navbar-bg;

		.navbar-brand {
			white-space: nowrap;

			.logo {
				height: 48px;
				fill: @navbar-font-color;
			}
		}
	}

	.nav.navbar-nav > li > a {
		font-weight: bold;
		color: @navbar-font-color;
	}

	.sub.navbar {
		.object-name {
			font-weight: bold;
			color: @navbar-sub-font-color;
		}

		.nav.navbar-nav > li > a,
		.nav.navbar-nav > li > app-institutions-auswahl > a {
			i {
				color: @brand-primary;
			}

			&.disabled {
				cursor: default;
				i {
					color: @gray;
				}
			}

			font-weight: bold;
			color: @navbar-sub-font-color;
		}

		.nav.navbar-nav.navbar-right > li > a,
		.nav.navbar-nav.navbar-right > li > app-institutions-auswahl > a {
			// Style-Anweisungen kopiert vom Bootstrap-Code, so dass sie auch auf a-Tag in Angluar-Komponenten angewendet werden,
			// bei denen der a-Tag nicht direkt unter dem li-Tag lebtwohnt (sondern noch ein app-something-Tag dazwischen ist.
			border-left: 1px solid @navbar-default-border;
			line-height: 22px;
			position: relative;
			display: block;
			padding: 14px 15px;
		}
	}
}
