@import "global-styles/variables.less";

.well {
	min-height: 200px;
}

.themen {
	font-size: @font-size-small;
	margin: 11px 0;
}

.portrait-container {
	padding: 10px 10px 10px 0;

	.portrait {
		height: 180px;
		box-shadow: 0 5px 15px rgba(0,0,0,0.2);
	}
}

.landscape-container {
	padding: 10px 10px 10px 0;

	.landscape {
		height: 320px;
		box-shadow: 0 5px 15px rgba(0,0,0,0.2);
	}
}
