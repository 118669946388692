//noinspection CssOptimizeSimilarProperties
.btn.btn-dashed {
	border-image-source: url("../assets/button/dashed.png");
	border-image-slice: 2;
	border-image-repeat: round;

	background-color: #f6f6f6;
	background-image: none;
	box-shadow: none;

	padding: 10px;

	color: @brand-primary;

	&:hover {
		background-color: @component-bg;
	}
}
