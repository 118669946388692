body.antworten {
	.sidebar {
		top: -22px;
		position: relative;

		background-color: @component-bg;
		padding: 20px;

		li {
			> h2 {
				margin: 0;
				padding: 9px 0;
				font-size: 15px;

				color: @btn-default-color;
				border-bottom: 1px solid @btn-default-color;
			}

			> ul {
				padding: 10px 5px;
				> li {
					padding-bottom: 10px;

					a {
						font-size: 14px;
					}
				}
			}

			&.disabled {
				> h2 {
					color: @gray-lighter;
					border-bottom: 1px solid @gray-lighter;
				}

				a {
					color: @gray-lighter;
				}
			}
		}

		.status-info {
			font-size: @font-size-small;
		}
	}

	.content {
		font-size: 14px;

		// Abschnitte
		h2 {
			color: @btn-default-color;
			border-bottom: 1px solid @btn-default-color;
			font-size: 18px;
			padding-bottom: 8px;
			margin-bottom: 20px;
		}

		// Fragen
		h3 {
			color: @brand-primary;
			font-size: 18px;
		}

		.disabled {
			> h2,
			.antwort > h3,
			.antwort > em {
				color: @gray-lighter;
			}
		}
		.halbeBreite{
			width: 50%;
		}
		.ganzeBreite{
			width: 100%;
		}

		.aktuelleVersion{
			float:left;
			.antwort {
				.diffGleich .panel{
					background-color: #c1e2b3;
				}
				.diffUngleich .panel{
					background-color: #e4b9b9;
				}
				.diffGleich .panel .panel-heading{
					background-color: #c1e2b3;
				}
				.diffUngleich .panel .panel-heading{
					background-color: #e4b9b9;
				}
			}
		}
		.urspruenglicheVersion{
			float:right;
			.antwort {
				.diffGleich .panel{
					background-color: #c1e2b3;
				}
				.diffUngleich .panel{
					background-color: #e4b9b9;
				}
				.diffGleich .panel .panel-heading{
					background-color: #c1e2b3;
				}
				.diffUngleich .panel .panel-heading{
					background-color: #e4b9b9;
				}
			}
		}

		.aktuelleVersion.diffGleich .panel{
			background-color: #c1e2b3;
		}
		.aktuelleVersion.diffUngleich .panel{
			background-color: #e4b9b9;
		}
		.aktuelleVersion.diffGleich .panel .panel-heading{
			background-color: #c1e2b3;
		}
		.aktuelleVersion.diffUngleich .panel .panel-heading{
			background-color: #e4b9b9;
		}
		.urspruenglicheVersion.diffGleich .panel{
			background-color: #c1e2b3;
		}
		.urspruenglicheVersion.diffUngleich .panel{
			background-color: #e4b9b9;
		}
		.urspruenglicheVersion.diffGleich .panel .panel-heading{
			background-color: #c1e2b3;
		}
		.urspruenglicheVersion.diffUngleich .panel .panel-heading{
			background-color: #e4b9b9;
		}

		.aktuelleVersion.diffUngleich{
			background-color: #e4b9b9 !important;
		}
		.aktuelleVersion.diffGleich{
			background-color: #c1e2b3 !important;
		}
		.urspruenglicheVersion.diffUngleich{
			background-color: #e4b9b9 !important;
		}
		.urspruenglicheVersion.diffGleich{
			background-color: #c1e2b3 !important;
		}

		.diffGleich table {
			table-layout: fixed;
		}
		.diffUngleich table{
			table-layout: fixed;
		}

		.antwort {
			margin-bottom: 45px;

			span, p {
				&.antwort-multiline {
					white-space: pre-wrap;
				}
			}

			.panel {
				.panel-heading {
					background-color: white;
				}

				// Feld-Titel
				h4 {
					color: @brand-primary;
					font-size: 14px;
					font-weight: bold;
					margin-top: 0;
				}

				.feld {
					margin-bottom: 30px;
					&:last-child {
						margin-bottom: 0;
					}
				}
			}

			.mehrfachauswahlantwort {
				li {
					list-style: none;
				}
			}

			.keine_antwort {
				color: @gray-lighter;
			}

			.checkbox-sign {
				font-size: 1.3em;
				font-weight: bold;
				line-height: 1em;
			}
			.checkbox-sign.yes {
				color: #000;
				font-size: 1.7em;
				line-height: 0.7em;
			}
			.checkbox-sign.no {
				color: #CCC;
			}
		}
	}
	.diffUngleich .table-striped>tbody>tr:nth-of-type(odd){
		background-color: #e4b9c0;
	}
	.diffGleich .table-striped>tbody>tr:nth-of-type(odd){
		background-color: rgba(176, 228, 154, 0.53);
	}

	.antwort-div{
		display: table;
		width: 100%;
	}
}
