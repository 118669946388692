@import "_bootstrap";
@import "variables";
@import "mixins";
@import "_body_height";
@import "_typography";
@import "_buttons";
@import "_fake-link";
@import "_enforce-scrollbar";
@import "_dashed-button";
@import "_icon-circled";
@import "_btn_toggle";
//
@import "../icons/style";
//
@import "../../node_modules/awesome-bootstrap-checkbox";
@import "awesome-bootstrap-checkbox-custom";

body .form-control[disabled],
.jurecContent.form-control[readonly],
body fieldset[disabled] .form-control {
	background-color: @component-disabled-bg;
}

body .required-marker {
	color: @brand-primary-dark;
	font-weight: 600;
}

body .modal-header {
	h1, h2, h3, h4, h5 {
		&.modal-title {
			color: @brand-primary-dark;
		}
	}

	.close {
		color: @brand-primary-dark;
		opacity: 0.5;
	}
}

body .p-component {
	font-family: "Open Sans", Helvetica, Arial, sans-serif;
	//font-size: 16px !important;
}

body .p-component,
body .p-paginator .p-paginator-pages .p-paginator-page,
body .p-inputtext,
body label {
	font-size: 14px !important;
}

body label {
	color: #404040
}

body .FullScreenDialog .p-dialog-title,
body .areaLabel,
body .p-dialog-title {
	font-size: 1.5rem !important;
}

body .p-button-label {
	font-size: 1.2rem;
	font-weight: bold;
}

body .p-component .p-button {
	padding: 0.45rem 1rem;
}

body .p-toast {
	font-size: 1.7rem !important;
}

body .areaLabel {
	font-weight: bold;
	margin: 10px 5px 5px 10px;

}

body .areaLabelAddon {
	margin: 13px 5px 5px 10px;
}

body .FullScreenDialog .p-dialog-header {
	background: white !important;
	border-radius: 0 !important;
}

body .FullScreenDialog {
	height: calc(100vh - 135px) !important;
	bottom: 0px !important;
	margin-bottom: -15px;
	margin-left: 10px;
	margin-right: 10px;
}

//body .FullScreenDialog .p-dialog-content{
//	height: calc(100vh - 150px) !important;
//}


body .p-card {
	background: #ffffff;
	color: #333333;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
	border-radius: 3px !important;
	border: 1px solid lightgrey;
}


body .p-toast-message {
	border-radius: 5px !important;
}

body .p-toast {
	position: fixed;
	min-width: 40rem;
	max-width: 55rem;
	width: auto;
	opacity: 0.90;
	margin-top: 30px;
	z-index: 1000000 !important;
}

body .p-toast:hover {
	opacity: 1;
}


.noKeyvisualBackground .keyvisual {
	background: none !important;
}
body ul.navbar-nav a {
	font-weight: bold;
	transition: all 0.5s;
	cursor: pointer;
}

body ul.navbar-nav a:hover {
}

body ul.navbar-nav a:focus {
	background: transparent;
}

body .navbar {
	background-color: white;
}

body .navbar .navbar-right {
	background-color: transparent;
}

body .hero .navbar-dark:hover ul.navbar-nav a {
	//text-shadow: none;
}

body .alert {
	white-space: pre;
}

body .container.teaser {
	background: none;
}

body {
	overflow: auto !important;
	height: calc(100vh - 1px);
	background-image: url("/assets/portal/hero.jpg"), linear-gradient(#0a2d57, #4073b5);
	background-size: cover;
	background-position: top;
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-color: #4073b5;
}

body .about-text {
	color: white !important;
}

body .hero {
	background: none !important;
}

body .p-tabview .p-tabview-panels {
	border-bottom-right-radius: 5px;
	border-bottom-left-radius: 5px;
	border-top-right-radius: 5px;
}

body .p-tabview .p-tabview-nav li .p-tabview-nav-link {
	background: #0a2d57;
	border-color: #0a2d57;
	color: #ffffff;
	border-bottom: 1px solid rgb(200, 200, 200) !important;
	transition: background 500ms;
	transition: color 500ms;
}

body .p-tabview .p-tabview-nav li:hover .p-tabview-nav-link {
	background: #0a2d57 !important;
	border-color: rgb(200, 200, 200) !important;
	color: #ffffff !important;
}

body .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
	background: #ffffff;
	border-color: #c8c8c8;
	color: #000000;
	border-bottom: 1px solid white !important;
}

body .p-tabview .p-tabview-nav li.p-highlight:hover .p-tabview-nav-link {
	background: #ffffff !important;
	border-color: rgb(200, 200, 200) !important;
	color: #000000 !important;
	border-bottom: 1px solid white !important;
}

body .footer-container {
	margin-bottom: 5px;
	opacity: 0.75;
}

body .p-card-content .p-formgrid {
	margin-bottom: 0;
}

body .p-card .p-card-footer {
	padding: 0;
}

body .p-disabled, body .p-component:disabled {
	opacity: 0.8;
	border-color: lightgray;
}

body .p-card .p-card-footer .ng-star-inserted {
	padding: 1rem 0 0 0;
}

body .archiveToggleButton .p-togglebutton .p-button-label {
	color: #FFFFFF !important;
	font-weight: bold !important;
}

body .archiveToggleButton .p-togglebutton.p-button.p-highlight {
	color: #FFFFFF;
	font-weight: bold;
	background-color: hsl(0, 99%, 44%) !important;
	border-color: hsl(212, 56%, 21%) !important;
	background-image: linear-gradient(0deg, rgba(0, 20, 50, 0.3) 0%, rgba(0, 0, 0, 0) 100%);
	transition: all 1s;
}

body .archiveToggleButton .p-togglebutton.p-button {
	color: #FFFFFF;
	font-weight: bold;
	background-color: hsl(100, 56%, 46%);
	border-color: hsl(212, 56%, 21%);
	background-image: linear-gradient(0deg, rgba(0, 20, 50, 0.3) 0%, rgba(0, 0, 0, 0) 100%);
	transition: all 1s;
}

body .archiveToggleButton:hover .p-togglebutton.p-button {
	background-color: hsl(98, 86%, 20%) !important;
	border-color: hsl(212, 56%, 21%) !important;
}

body .archiveToggleButton:hover .p-togglebutton.p-button.p-highlight {
	background-color: hsl(0, 100%, 25%) !important;
	border-color: hsl(212, 56%, 21%) !important;
}

body .archiveToggleButton .p-togglebutton .p-button-icon {
	color: #FFFFFF !important;
	font-weight: bold;
}

body .p-component .table-button {
	background-color: white;
	padding: 0.45rem 0.65rem;
}

body .p-component .p-button.p-button-text:hover {
	border: 1px solid lightskyblue;
}

body .p-component .p-button:hover {
	border: 1px solid #3a67a1;
}

body tr:hover .table-button, body tr:hover .table-trash-button {
	border: 1px solid #768a93 !important;
}

body tr:hover .table-button .pi, body tr:hover .table-trash-button .pi {
	text-shadow: 0px 0px 3px #768a93;
}

body .selectableRow {
	transition: all .5s;
}

body .table-button {
	transition: all .5s;
}

body .table-button {
	background-color: #116fbf !important;
	color: white !important;
	outline: #116fbf !important;
	border: 1px solid #116fbf !important;
}

body .table-button-green {
	background-color: #079716 !important;
	color: white !important;
	outline: #079716 !important;
	border: 1px solid #079716 !important;
}

body .table-trash-button {
	background-color: #c40000 !important;
	color: white !important;
	outline: #c40000 !important;
	border: 1px solid #c40000 !important;
}

.whiteBackBtn {
	background-color: white !important;
}

.p-tag {
	padding-left: 10px !important;
	padding-right: 10px !important;
}

.hoverline:hover {
	background-color: lightgrey;
}


body .blue-panel.contact-container {
	min-height: 150px !important;
	background: none !important;
}

body .blue-panel {
	background: hsla(212.7, 79.4%, 19%, 0.7) !important;
}


body .searchField {
	width: 120px;
	text-overflow: ellipsis;
	transition: all .5s;
}

body .p-input-icon-left:hover .searchField {
	width: 160px;
}

body .searchField:focus {
	width: 230px !important;
}

.noDialog .p-dialog-mask, .noDialog .p-component-overlay, .noDialog .p-dialog-mask-scrollblocker {
	display: none !important;
}

.selectableRow {
	cursor: pointer;
	height: 41px;
}

.p-button-icon-only {
	transition: outline 500ms;
}

.p-button-icon-only:hover {
	outline: 1px solid lightblue;
}

body .p-accordion .p-tag.p-tag-success, body .p-accordion .p-tag.p-tag-danger {
	padding: 5px;
	font-weight: bold;
	width: 110px;
}

//body .p-accordion .p-button-rounded, body .table-button {
//	padding-left: 7px;
//}

body .table-button .ng-fa-icon {
	padding-left: 2px;
	margin-right: -2px;
	padding-top: 1px;
}

body .p-component .table-button.table-text-button .ng-fa-icon {
	padding-left: 1px;
	margin-right: 5px;
}

body .p-accordion-content .table-button .ng-fa-icon {
	padding-left: 1px;
	margin-right: 1px;
}

body .table-button.wideIconButton .ng-fa-icon {
	padding-left: 1px;
	margin-right: -3px;
}

body .anredeFilter .p-dropdown, body .anredeFilter .p-dropdown-clearable {
	width: 11rem;
}

body .p-column-filter-row .p-dropdown-label {
	padding-left: 20px;
}

body .p-datatable .p-datatable-thead > tr > th {
	text-align: center;
}

body .overflowDialog .p-dialog-content {
	overflow: visible;
}

body .width500Dialog .p-dialog-content {
	width: 500px;
}

body .p-column-filter-row .p-calendar .p-inputtext {
	padding-left: 20px;
}

body .p-datepicker table td {
	padding: 0.2rem;
}

body .aktuelleInstitutionWrapper .p-dropdown-label {
	padding-left: 20px;
	padding-right: 20px;
}

body .aktuelleInstitutionWrapper {
	min-height: auto !important;
	z-index: 100;
}

body .p-toast-summary {
	white-space: pre-wrap;
	padding-left: 10px;
	padding-right: 20px;
	margin-top: 0.5rem;
}

body .p-toast-detail {
	white-space: pre-wrap;
	padding-left: 10px;
	padding-right: 20px;
}

body .p-toast-icon-close-icon {
	font-weight: bold;
	font-size: 1.5rem;
}

body .p-toast-message-icon {
	margin-top: 5px;
	margin-left: 10px;
}

body .p-fluid .p-inputtext {
	padding-left: 10px;
}

body app-team .container .row {
	background-color: white;
}

body app-team h1 {
	margin: 30px;
	font-family: Open Sans, Helvetica, Arial, sans-serif;
	text-align: center;
	letter-spacing: 3px;
}

#benutzer_bearbeiten_inner_div .p-card-footer {
	margin-top: -20px;
}

.bigButton {
	padding: 4px 6px;
}

.bigButton .p-button-label, .bigButton .p-button-icon {
	font-size: 14px;
}

.bigButton .p-button-icon {
	margin-right: 1rem !important;
}

.noBackgroundFix button {
	background: transparent;
	color: #414141;
	border: none;
}

.noBackgroundFix:hover button, .noBackgroundFix button:hover {
	background: transparent !important;
	color: #414141 !important;
	border: none !important;
}

.alert, .alert-success, .alert-danger, .alert-warning, .alert-info {
	border-radius: 10px;
	font-weight: bold;
	box-shadow: 0.3px 0.3px 0.4px rgba(0, 0, 0, 0.037),
	0.7px 0.7px 0.9px rgba(0, 0, 0, 0.061),
	1.3px 1.3px 1.8px rgba(0, 0, 0, 0.078),
	2.2px 2.2px 3.1px rgba(0, 0, 0, 0.088),
	4.2px 4.2px 5.8px rgba(0, 0, 0, 0.087),
	10px 10px 14px rgba(0, 0, 0, 0.07);
	opacity: 0.9;
}

.alert:hover {
	opacity: 1;
}

.alert-success {
	background: rgb(180, 221, 180); /* Old browsers */
	background: -moz-linear-gradient(top, rgba(180, 221, 180, 1) 0%, rgb(82, 177, 82) 96%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, rgba(180, 221, 180, 1) 0%, rgb(82, 177, 82) 96%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, rgba(180, 221, 180, 1) 0%, rgb(82, 177, 82) 96%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#b4ddb4', endColorstr='#008a00',GradientType=0); /* IE6-9 */
	color: black;
	border-color: #22B205FF;
}

.alert-info {
	background: rgb(122, 188, 255); /* Old browsers */
	background: -moz-linear-gradient(top, rgba(122, 188, 255, 1) 0%, rgba(96, 171, 248, 1) 44%, rgba(64, 150, 238, 1) 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, rgba(122, 188, 255, 1) 0%, rgba(96, 171, 248, 1) 44%, rgba(64, 150, 238, 1) 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, rgba(122, 188, 255, 1) 0%, rgba(96, 171, 248, 1) 44%, rgba(64, 150, 238, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#7abcff', endColorstr='#4096ee',GradientType=0); /* IE6-9 */
	color: black;
	border-color: #4096EEFF;
}

.alert-warning {
	background: rgb(255, 197, 120); /* Old browsers */
	background: -moz-linear-gradient(top, rgba(255, 197, 120, 1) 0%, rgba(251, 157, 35, 1) 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, rgba(255, 197, 120, 1) 0%, rgba(251, 157, 35, 1) 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, rgba(255, 197, 120, 1) 0%, rgba(251, 157, 35, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffc578', endColorstr='#fb9d23',GradientType=0); /* IE6-9 */
	color: black;
	border-color: orange;
}

.alert-danger {
	background: rgb(169, 3, 41); /* Old browsers */
	background: -moz-linear-gradient(top, rgba(169, 3, 41, 1) 0%, rgba(143, 2, 34, 1) 44%, rgba(109, 0, 25, 1) 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, rgba(169, 3, 41, 1) 0%, rgba(143, 2, 34, 1) 44%, rgba(109, 0, 25, 1) 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, rgba(169, 3, 41, 1) 0%, rgba(143, 2, 34, 1) 44%, rgba(109, 0, 25, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#a90329', endColorstr='#6d0019',GradientType=0); /* IE6-9 */
	color: white;
	border-color: #A90329FF;
}

.jurecContent .p-tabview-panels .row {
	margin-left: 0 !important;
	margin-right: 0 !important;
}

.jurecContent .p-tabview-panels {
	//padding: 0 !important;
	//margin-top: 12.5px;
}

.jurecContent .modal-dialog li {
	padding-left: 0.5em;
	text-indent: -0.5em;
}

.jurecContent .navbar-nav {

}

.jurecContent .navbar-nav li {
	transition: all 0.5s;
	border-radius: 5px;
}

.jurecContent .navbar-nav li:hover {
	background-color: white;
	color: black !important;;
}

.jurecContent .navbar-nav li:hover a{
	color: black !important;
	background: transparent !important;
}

.jurecContent .navbar-nav li a:focus {
	color: white;
}

.jurecContent .p-tabview-nav {
	font-size: 14px;
	margin-bottom: 0;
}


.antwort-multiline {
	white-space: pre-line;
}

//fixt Bootstrap Modal fade-in
.show {
	opacity: 1;
	transition: opacity 0.15s linear;
}

.modal.fade .modal-dialog {
	transform: translateY(0%);
}

table {
	border-collapse: collapse;
}
