@import "global-styles/variables.less";

body.content-page {
	background-color: white;
	color: @gray-light;
	min-height: 100%;

	h1, h2, h3, h4, h5 {
		color: @gray;
	}

	nav.navbar {
		background: @brand-primary-dark;
		color: @brand-primary-dark-font-color;
		margin-bottom: 40px;
		border-radius: 0;
		height: auto;

		a, a:visited {
			margin-left: 5px;
			margin-right: 5px;
			text-decoration: none;
			color: white;
			background: fadeout(@brand-primary, 100%);
			border-radius: 4px;
			transition: background 0.3s ease-in-out;
		}
		ul.navbar-nav {
			padding: 10px 0;

			a:hover {
				background: fadeout(@brand-primary, 30%);
			}
			li {
				margin-bottom: 0;
			}

			@media screen and (min-width: @screen-sm) and (max-width: @screen-lg) {
				width: 70%;
			}
		}

	}
	nav.navbar-dark {
		a, a:visited {
			margin-left: 5px;
			margin-right: 5px;
			text-decoration: none;
			color: #333333;
			background: fadeout(@brand-primary, 100%);
			border-radius: 4px;
			transition: background 0.3s ease-in-out;
		}
	}

	h1 {
		font-family: "Times New Roman", Times, serif;
		font-size: 36px;
		letter-spacing: 0.3px;
		line-height: 40px;
		margin: 0 0 30px 0;
	}

	.padded {
		padding: 80px 0;
	}

	.offset-top {
		margin-top: 20px;
	}

	.panel.form-panel {
		box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.15);
		border: none;

		h1, h2, h3, h4 {
			padding-top: 0;
			margin-top: 0;

		}

		h2 {
			font-family: "Times New Roman", Times, serif;
		}
		nav.pull-left {
			.btn-link {
				padding-left: 0;
				background-image: none;
			}
		}

		.panel-footer {
			padding: 20px;
			color: #FFF;
			background-color: @brand-primary-dark;
			h1, h2, h3, h4 {
				color: #FFF;
			}
		}
	}

	.ansprechpartner {
		text-align: center;

		h2 {
			font-family: "Times New Roman", Times, serif;
			line-height: 1.3;
			margin: 2px 0 30px 0;
			color: white;
		}

		> .well {
			color: @gray;

			.btn {
				display: inline-block;
				width: 100%;
				text-align: center;
			}

			.tel a {
				color: black;
			}

			h3 {
				font-weight: bold;
				margin: 0;
				color: @brand-primary-dark;
			}

			.jobtitle {
				font-size: @font-size-small;
				color: darken(@gray-lighter, 20%);
				margin-bottom: 16px;
			}
		}
	}
	.contacts {
		margin-top: 10px;
	}

	.contact-container {
		padding: 15px 0;
		display: flex;

		.contact-image {
			width: 64px;
			height: 64px;
			margin-right: 20px;
			flex: 0 0 auto;
			border-radius: 100%;
			overflow: hidden;

			img.avatar {
				margin: 0;
				width: 70px;
				height: 70px;
				margin-top: -3px;
				margin-left: -3px;
			}

		}
		.contact-details {
			font-size: @font-size-small;
			width: 100%;
			flex: 1 1 auto;

			h4 {
				margin: 0;
			}

			.jobtitle {
				color: rgba(255, 255, 255, 0.7);
				margin-bottom: 8px;

			}
		}
		.contact-mail {
			flex: 1 0 auto;
			align-self: flex-start;
		}

	}

	.centered {
		text-align: center;
	}

	&.keyvisual {
		background-image: url("/assets/portal/hero.jpg");
		background-size: cover;
		background-position: top;
		background-repeat: no-repeat;
		background-attachment: fixed;
		height: 95vh;
	}

	&.portal {
		@import "content-page/_portal";
	}
	&.meta-portal {
		@import "content-page/_portal-meta";
	}
	&.faq {
		@import "content-page/_faq";
	}
	&.team {
		@import "content-page/_team";
	}
	&.message {
		@import "content-page/_message";
	}
	&.simple-form {
		@import "content-page/_simple_form";
	}
	&.video-tutorials {
		@import "content-page/_video-tutorials";
	}
	&.benutzerverwaltung {
		@import "content-page/_benutzerverwaltung";
	}
	&.faq-partials {
		@import "content-page/_faq-partials";
	}
}
