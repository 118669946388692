/**
 * Achtung: Diese Angaben gestalten zusaetzlich zu spinner.twig auch SpinnerComponent.html!
 **/

.spinner {
	width: 96px;
	margin: 0 auto;
	padding: 40px 0;
	position: relative;

	.spin-rotate {
		position: absolute;
		animation: spin 2s linear 0s infinite;
		transform: translateZ(0);
	}
}

@keyframes spin {
	from {
		transform: rotate(0deg)
	}
	to {
		transform: rotate(359deg)
	}
}
