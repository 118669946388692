/**
 * Diese Datei passt das Aussehen und die Platzierung der gestalteten Checkboxen & Radiobuttons, die so-called
 * "Awesome Bootstrap Checkboxes (abc-checkbox)" an das Jurec-Projekt an. Insb. wird das Checkmark-Icon durch eins aus dem Jurec-Font
 * ausgetauscht, da wir fontawesome nicht als eigentständigen Font laden. Daraus ergeben sich auch Unterschiede in der Positionierung.
 *
 * Zusätzlich wird die visuelle Erscheinung von Radiobuttons und Checkboxen angeglichen: Beide sind nun rund und beide enthalten ein
 * Haken-Symbol (by-default sind Checkboxen eckig und Radio-Buttons haben einen gefüllten Kreis als "ausgewählt"-Symbol,
 * vgl. http://flatlogic.github.io/awesome-bootstrap-checkbox/demo/)
 */

.radio, .checkbox {
	padding-left: 20px;
}

.abc-checkbox input[type="checkbox"] {
	position: absolute;
}

.checkbox {
	min-height: 30px;
	padding-left: 0;

	.list-group-item & {
		padding-left: 20px;
	}

	label {
		padding-left: 20px;
		font-size: 16px;

		&:empty {
			padding-left: 1px;
		}

		&::before {
			border-radius: 50%;
			width: 30px;
			height: 30px;
			top: -2px;
		}
		&::after {
			width: 23px;
			height: 23px;
			font-size: 23px;

			top: 0;
			left: 0;
			color: @brand-primary;
		}
	}

	input[type="radio"]:indeterminate + label::after {
		display: none;
	}

	input[type="checkbox"]:checked + label::after,
	input[type="radio"]:checked + label::after {
		/* use !important to prevent issues with browser extensions that change fonts */
		font-family: 'jurec-icons' !important;
		speak: none;
		font-style: normal;
		font-weight: normal;
		font-variant: normal;
		text-transform: none;
		line-height: 1;

		/* Better Font Rendering =========== */
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;

		content: @icon-check;
	}
}
