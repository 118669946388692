@import "global-styles/variables.less";

.footer-container {
	background: linear-gradient(0deg, #B6C0CC 0%, #C2CAD4 100%);
	font-size: @font-size-medium;
	line-height: 24px;
	padding: 1em 2em;
	color: @footer-font-color;

	a {
		color: @footer-font-color;
	}
}
