@bspath: "../../node_modules/bootstrap/less/";
@import "@{bspath}/variables.less";

@body-bg: #FFFFFF;

@well-bg: #FFFFFF;

@brand-primary: hsl(212, 56%, 46%);
@brand-primary-dark: hsl(213, 79%, 19%);
@brand-primary-dark_opacity: hsl(213, 79%, 19%, 0.75);
@brand-primary-dark-font-color: white;
@brand-primary-dark-hover-color: #f5f5f5;

@error-red: hsl(0, 56%, 46%);

@state-warning-text: @brand-primary;
@state-warning-bg: #fcf8e3;

@subtext-color: #9B9B9B;

@navbar-bg: @brand-primary-dark_opacity;
@navbar-font-color: @brand-primary-dark-font-color;
@navbar-sub-font-color: @brand-primary-dark;

@footer-font-color: #4A4A4A;

@brand-danger: #D1D1D1;
@btn-danger-color: #333333;

@gray-lighter: #CECECE;
@blue-dark: #082A57;
@component-bg: #EEEEEE;
@viewer-header-bg: @brand-primary-dark;
@component-border: 1px solid #CECECE;
@component-disabled-bg: #f9f9f9;

@input-border-focus: @brand-primary-dark;

@table-header-bg: @component-bg;
@table-border-color: @gray-lighter;
@table-cell-padding: 8px;

@alert-boxes-z-index: 2000;

@font-family-sans-serif: "Open Sans", Helvetica, Arial, sans-serif;
@font-family-base: @font-family-sans-serif;

@font-size-base: 16px;
@font-size-large: 20px;
@font-size-medium: 14px;
@font-size-small: 12px;

@font-size-h1: 32px;
@font-size-h2: 24px;
@font-size-h3: 20px;
@font-size-h4: 18px;
@font-size-h5: @font-size-base;
@font-size-h6: 12px;

@font-size-print-base: 11px;
@font-size-print-small: 9px;
@font-size-print-medium: 13px;
@font-size-print-large: 15px;

@font-size-print-h1: 17px;
@font-size-print-h2: 15px;
@font-size-print-h3: 13px;

@border-radius-base: 3px;
@border-radius-large: 4px;
@border-radius-small: 2px;

@padding-base-vertical: 8px;

@input-bg: #FFFFFF;
@input-bg-disabled: @gray-lighter;
@input-font-disabled: #828279;
@input-font-disabled-hover: darken(@input-font-disabled, 15%);
@input-border: #CCCCCC;

@input-color: @brand-primary-dark;
@input-color-placeholder: @gray-lighter;
@input-border-focus: @brand-primary;

@btn-font-weight: bold;

@btn-default-color: #4A4A4A;
@btn-default-bg: #FAFAFA;
@btn-default-border: #CECECE;
@btn-default-hover: darken(@btn-default-bg, 3%);

@btn-primary-color: #FFFFFF;
@btn-primary-bg: @brand-primary;
@btn-primary-border: darken(@btn-primary-bg, 25%);
@btn-primary-hover: lighten(@btn-primary-bg, 5%);

@tooltip-max-width: 300px;
@tooltip-bg: @brand-primary-dark;
@tooltip-opacity: 1;

.tooltip-inner {
	padding: 8px;
}

@panel-bg: #fff;
@panel-body-padding: 20px;
@panel-heading-padding: 10px 20px;

//** Border color for elements within panels
@panel-inner-border: #fff;
@panel-footer-bg: @panel-bg;

@panel-default-text: @gray-dark;
@panel-default-heading-bg: @panel-bg;
