@import "_common";
@import "_navbar";
@import "_footer";
@import "_spinner";
@import "_viewer";
@import "_content-page";
@import "_antworten";

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
