.btn {
	font-size: @font-size-medium;
	transition: all 0.05s ease-in-out;
	background-image: linear-gradient(0deg, rgba(0,0,0,0.05) 0%, rgba(0,0,0,0) 100%);

	&:focus {
		outline: 0 !important; // Chrome's blaue Outline weg.
	}

	&.btn-default {
		&:hover {
			background-color: @btn-default-hover;
			border: 1px solid #C2C2C2;
		}
	}

	&.btn-primary {
		background-image: linear-gradient(0deg, rgba(0,20,50,0.30) 0%, rgba(0,0,0,0) 100%);
		&:hover {
			background-color: @btn-primary-hover;
		}
	}

	[class^="icon-"], [class*=" icon-"] {
		position: relative;
		font-size: 20px;
		line-height: 0.4;
		top: 3px;
	}
}
