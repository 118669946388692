@import "./_open-sans.less";

body {
	/* Spezielle Anweisungen die Autoprefixr nicht macht..
	 * Sorgen dafür dass die Schrift nicht merkwürdig aussieht auf Nicht-Retina-Geräten. */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-smooth: always;
	letter-spacing: 0.01em;
}

h1, h2, h3, h4 {
	letter-spacing: normal;
}

label {
	font-size: @font-size-small;
	font-weight: normal;
	color: @gray-light;
	letter-spacing: 0.02em;
}

// für die kleinere Label-Schriftart kompensieren
input[type="radio"], input[type="checkbox"] {
	margin-top: 3px;
}

.form-control {
	font-size: @font-size-medium;
}

.serif {
	font-family: "Times New Roman", Times, serif;
}

@media print {
	h1 {
		font-size: @font-size-print-h1;
	}

	h2 {
		font-size: @font-size-print-h2;
	}

	h3 {
		font-size: @font-size-print-h3;
	}

	body, label, .form-control {
		font-size: @font-size-print-base;
	}
}
