@font-face {
	font-family: 'Oswald';
	font-style: normal;
	font-weight: 400;
	src: url('/assets/fonts/Oswald/Oswald-Regular.ttf') format("truetype");
}

header {
	background-color: @viewer-header-bg;
	padding: 20px 0 30px 0;

	.logo-text {
		color: #FFF;
		display: inline-block;
		position: relative;
		font-family: "Oswald", sans-serif;
		letter-spacing: -0.01em;
		font-size: 20px;
		height: 52px;
		top: 5px;

		.logo-text-line {
			position: absolute;
			left: 0;
			width: 100%;
			text-transform: uppercase;
			white-space: nowrap;

			&.top {
				top: 0;
				opacity: 0.5;
			}

			&.bottom {
				bottom: 0;
			}
		}
	}

	.container {
		background: url("/assets/header/silhouetten.png") no-repeat center right;
		background-size: 40%;
	}

}

img.recherchekomplex-header {
	max-width: 100%;
	height: auto;
}
