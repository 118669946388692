@import "global-styles/variables.less";

h1 {
	font-family: "Times New Roman", Times, serif;
	font-size: 36px;
	letter-spacing: 0.3px;
	line-height: 40px;
	margin: 0 0 30px 0;
}

.hero {
	background-color: @brand-primary-dark;
	color: white;
	margin-bottom: 60px;
	position: relative;
	min-height: 300px;
	text-align: center;

	.keyvisual {
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
		background-color: @brand-primary;
		background-image: url("/assets/portal/hero.jpg");
		min-height: 310px;
		height: 40vh;

		@media screen and (max-width: 767px) {
			min-height: 370px;
		}
	}

	.navbar {
		background: none;

		a, a:visited {
			color: black;
		}
	}

	a, a:visited, strong, h1 {
		color: white;
	}

	.welcome {
		padding: 60px 0 80px 0;
		padding: 5vh 0 10vh 0;

		a, a:visited {
			text-decoration: underline;
		}
	}

	.institution {
		opacity: 0.7;
		font-size: 15px;
		color: #FFFFFF;
		letter-spacing: 0.3px;
		line-height: 24px;
		text-transform: uppercase;
		margin: 0 0 10px;
	}
}

.well {
	min-height: 310px;

	&.ansprechpartnerbox {
		min-height: 238px;
	}
}

.fragebogenliste {
	h2 .controls {
		margin: 0 5px;
	}
}

.giant-arrow {
	position: relative;
	font-size: 36px;
	padding-top: 80px;
	padding-top: 7vh;
	top: 0;
	animation: bounz 1s ease-in-out 0s infinite alternate;
	transform: translateZ(0);

	a {
		text-decoration: none !important;
	}

	@keyframes bounz {
		from {
			top: 0;
			opacity: 1;
		}
		to {
			top: 15px;
			opacity: 0.5;
		}
	}
}

li.abschnitt {
	position: relative;
	padding: 0;
	margin: 0;
	min-height: 70px;
	list-style: none;
	background-color: @body-bg;
	border-left: 1px solid @gray-lighter;
	border-right: 1px solid @gray-lighter;
	border-bottom: 1px solid @gray-lighter;

	&:first-child {
		border-top: 1px solid @gray-lighter;
		border-top-right-radius: 3px;
		border-top-left-radius: 3px;
	}

	&:last-child {
		border-bottom-right-radius: 3px;
		border-bottom-left-radius: 3px;
	}

	.text {
		padding: 15px 214px 15px 15px;

		.title {
			line-height: 1.2;
		}
	}

	.description {
		margin-top: 2px;
		font-size: @font-size-small;
		font-weight: bold;
		color: @subtext-color;

		.subtitle-anzahl-fragen {
			white-space: nowrap;
		}
	}

	.buttons-right {
		position: absolute;
		top: 15px;
		right: 15px;
	}

	&.blau {
		border-color: @brand-primary;
		background-color: lighten(@brand-primary, 48%);

		.description {
			color: @brand-primary;
		}

		a.btn-primary {
			width: 141px;
		}
	}
}

.notification-box {
	position: relative;
	padding: 10px;
	margin-bottom: @font-size-medium;
	border-radius: 3px;
	border: 1px solid @brand-primary;
	color: @brand-primary;
	background-color: lighten(@brand-primary, 48%);

	i {
		font-size: @font-size-large;
		position: absolute;
		top: 10px;
		left: 10px;
	}

	p {
		font-size: @font-size-medium;
		padding: 0;
		margin: 0 0 0 28px;
	}
}

.teaser-box-background {
	width: 100%;
}
