@import "variables";

@font-face {
	font-family: '@{icomoon-font-family}';
	src: url('@{icomoon-font-path}/@{icomoon-font-family}.eot?wbujb0');
	src: url('@{icomoon-font-path}/@{icomoon-font-family}.eot?wbujb0#iefix') format('embedded-opentype'),
	url('@{icomoon-font-path}/@{icomoon-font-family}.ttf?wbujb0') format('truetype'),
	url('@{icomoon-font-path}/@{icomoon-font-family}.woff?wbujb0') format('woff'),
	url('@{icomoon-font-path}/@{icomoon-font-family}.svg?wbujb0#@{icomoon-font-family}') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: '@{icomoon-font-family}' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-plus-circle {
	&:before {
		content: @icon-plus-circle;
	}
}

.icon-minus-circle {
	&:before {
		content: @icon-minus-circle;
	}
}

.icon-times-circle {
	&:before {
		content: @icon-times-circle;
	}
}

.icon-check-circle {
	&:before {
		content: @icon-check-circle;
	}
}

.icon-chain {
	&:before {
		content: @icon-chain;
	}
}

.icon-link {
	&:before {
		content: @icon-link;
	}
}

.icon-multiselect {
	&:before {
		content: @icon-multiselect;
	}
}

.icon-thermometer-1 {
	&:before {
		content: @icon-thermometer-1;
	}
}

.icon-thermometer-quarter {
	&:before {
		content: @icon-thermometer-quarter;
	}
}

.icon-download {
	&:before {
		content: @icon-download;
	}
}

.icon-upload {
	&:before {
		content: @icon-upload;
	}
}

.icon-clipboard {
	&:before {
		content: @icon-clipboard;
	}
}

.icon-mail {
	&:before {
		content: @icon-mail;
	}
}

.icon-users {
	&:before {
		content: @icon-users;
	}
}

.icon-export {
	&:before {
		content: @icon-export;
	}
}

.icon-chevron-down {
	&:before {
		content: @icon-chevron-down;
	}
}

.icon-chevron-up {
	&:before {
		content: @icon-chevron-up;
	}
}

.icon-chevron-left {
	&:before {
		content: @icon-chevron-left;
	}
}

.icon-chevron-right {
	&:before {
		content: @icon-chevron-right;
	}
}

.icon-lock-success {
	&:before {
		content: @icon-lock-success;
	}
}

.icon-card {
	&:before {
		content: @icon-card;
	}
}

.icon-table {
	&:before {
		content: @icon-table;
	}
}

.icon-text-t {
	&:before {
		content: @icon-text-t;
	}
}

.icon-yn {
	&:before {
		content: @icon-yn;
	}
}

.icon-chevron-small-down {
	&:before {
		content: @icon-chevron-small-down;
	}
}

.icon-chevron-small-left {
	&:before {
		content: @icon-chevron-small-left;
	}
}

.icon-chevron-small-right {
	&:before {
		content: @icon-chevron-small-right;
	}
}

.icon-chevron-small-up {
	&:before {
		content: @icon-chevron-small-up;
	}
}

.icon-chevron-thin-down {
	&:before {
		content: @icon-chevron-thin-down;
	}
}

.icon-chevron-thin-left {
	&:before {
		content: @icon-chevron-thin-left;
	}
}

.icon-chevron-thin-right {
	&:before {
		content: @icon-chevron-thin-right;
	}
}

.icon-chevron-thin-up {
	&:before {
		content: @icon-chevron-thin-up;
	}
}

.icon-trash {
	&:before {
		content: @icon-trash;
	}
}

.icon-triangle-down {
	&:before {
		content: @icon-triangle-down;
	}
}

.icon-triangle-left {
	&:before {
		content: @icon-triangle-left;
	}
}

.icon-triangle-right {
	&:before {
		content: @icon-triangle-right;
	}
}

.icon-triangle-up {
	&:before {
		content: @icon-triangle-up;
	}
}

.icon-check {
	&:before {
		content: @icon-check;
	}
}

.icon-cog {
	&:before {
		content: @icon-cog;
	}
}

.icon-copy {
	&:before {
		content: @icon-copy;
	}
}

.icon-cross {
	&:before {
		content: @icon-cross;
	}
}

.icon-edit {
	&:before {
		content: @icon-edit;
	}
}

.icon-eye {
	&:before {
		content: @icon-eye;
	}
}

.icon-help-with-circle {
	&:before {
		content: @icon-help-with-circle;
	}
}

.icon-info-with-circle {
	&:before {
		content: @icon-info-with-circle;
	}
}

.icon-lock {
	&:before {
		content: @icon-lock;
	}
}

.icon-color-save {
	&:before {
		content: @icon-color-save;
	}
}

.icon-color-sanduhr {
	&:before {
		content: @icon-color-sanduhr;
	}
}

.icon-plus {
	&:before {
		content: @icon-plus;
	}
}

