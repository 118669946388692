@import "global-styles/variables.less";

.hero {
	background-color: white;
	margin-bottom: 0;
	padding-bottom: 0;
	position: relative;
	min-height: 100%;

	.keyvisual {
		background-size: cover;
		background-position: center top;
		background-repeat: repeat-y;
		background-color: @brand-primary;
		background-image: url("../../assets/portal/hero.jpg");
		padding: 0 0 40px 0;
		margin-bottom: 40px;

		.keyvisual-text {
			margin-bottom: 80px;
			color: #FFF;
			h1, h2, h3, h4 {
				color: #FFF;
			}
			a, a:visited {
				color: #FFF;
			}

		}
	}

	nav.navbar {
		background: rgba(0, 0, 0, 0.3);
		a, a:visited {
			color: #FFF;
		}
	}

}

.well {
	min-height: 310px;
}

.form-panel {
	h2 {
		margin: 0 0 10px;
	}

}

.blue-panel {
	background: @brand-primary-dark;
	color: @brand-primary-dark-font-color;
	box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.15);
	border-radius: 3px;
	padding: 25px;
	margin-bottom: 20px;
	h3, h4 {
		margin: 0 0 4px;
		color: #FFF;
	}
	h3 + h3 {
		margin: 0 0 10px;
		opacity: 0.7;
	}

	.contact-container {
		padding-bottom: 0;
	}
}

.about-juve {
	padding: 140px 0;

	.logo {
		padding: 0 0 40px;
	}
	.about-text {
		margin: 0 auto;
		max-width: 700px;
	}

}

.notification-box {
	position: relative;
	padding: 10px;
	margin-bottom: @font-size-medium;
	border-radius: 3px;
	border: 1px solid @brand-primary;
	color: @brand-primary;
	background-color: lighten(@brand-primary, 48%);
	i {
		font-size: @font-size-large;
		position: absolute;
		top: 10px;
		left: 10px;
	}
	p {
		font-size: @font-size-medium;
		padding: 0;
		margin: 0 0 0 28px;
	}
}

.teaser-box-background {
	width: 100%;
}
