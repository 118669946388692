.container {
	padding-bottom: 30px;
}

iframe.youtube {
	border: 1px solid #0a2d57;
}

h2 {
	margin-top: 0px;
	margin-bottom: 20px;
}

h2 a {
	color: inherit;
	text-decoration: none;

	&:hover {
		text-decoration: none;
	}
}

hr {
	margin-top: 80px;
	margin-bottom: 60px;
}

.navibox {
	@media screen and (max-width: 980px) {
		display: none;
	}

	li {
		margin-bottom: 5px;
	}
}
