// looks like a link, feels like a link but is nor link
span.link {
	cursor: pointer;
	color: @link-color;
	text-decoration: none;

	&:hover,
	&:focus {
		color: @link-hover-color;
		text-decoration: @link-hover-decoration;
	}

	&:focus {
		.tab-focus();
	}
}
