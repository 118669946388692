ul {
	padding-left: 30px;
	li {
		margin-bottom: 10px;
	}
}

h2 {
	font-family: "Times New Roman", Times, serif;
	color: @gray;
	padding-bottom: 6px;
	border-bottom: 1px solid @gray-light;
	margin-top: 80px;
	margin-bottom: 40px;
}

h2 + h3 {
	margin-top: 20px;
}

h3 {
	color: @brand-primary-dark;
	margin: 40px 0 10px 0;
	font-size: 110%;
	line-height: 1.4;
}

h5 {
	margin: 40px 0 10px 0;
	font-weight: bold;
	text-transform: uppercase;
}

.divider {
	border-top: 1px solid @gray-lighter;
	border-bottom: 1px solid @gray-lighter;
	padding: 20px 0;
	margin: 60px 0;
	text-align: center;
}

hr {
	margin: 20px 0;
}
