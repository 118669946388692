& {
	height:100vh;

	.footer-container {
		background: none;
		text-align: center;
		color: white;
		padding:40px 0;

		a, a:visited {
			color: white;
		}
	}
}

.flex-center {
	display:flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}


.logo {
	text-align: center;
	padding: 50px;
}



.message{
	text-align: center;
	padding: 100px 60px 100px;
}
