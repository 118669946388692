.btn-toggle {
	border: 2px solid @brand-primary;
	background: white;
	color: @brand-primary;
	padding: 5px 11px;

	&.btn-checked, &:hover {
		background: @brand-primary;
		color: white;
	}

	&[disabled]:hover {
		background: white;
		color: @brand-primary;
	}

	&[disabled].btn-checked:hover {
		background: @brand-primary;
		color: white;
	}
}
